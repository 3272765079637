<template>
  <v-dialog v-model="dialog" persistent max-width="290">
    <template v-slot:activator="{ on, attrs }">
      <v-list-item v-bind="attrs" v-on="on">
        <v-list-item-content>
          <v-btn depressed block color="error" max-width="600px">
            Logout
          </v-btn>
        </v-list-item-content>
      </v-list-item>
    </template>
    <v-card>
      <v-card-title class="text-h5">
        Account Logout
      </v-card-title>
      <v-card-text>
        Are you sure you want to log out?
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="darken-1" text @click="dialog = false" data-testid="logout-cancel">
          Cancel
        </v-btn>
        <v-btn color="red darken-1" text @click="onClickLogout" data-testid="logout-confirm">
          Log Me Out
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'LogoutListItem',

  data() {
    return {
      dialog: false
    };
  },
  methods: {
    onClickLogout() {
      this.$emit('clickLogout');
    }
  }
};
</script>
