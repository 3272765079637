var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-toolbar",
    { attrs: { dense: "" } },
    [
      _c("v-toolbar-title", [_vm._v(" Settings ")]),
      _c("v-spacer"),
      _c(
        "v-toolbar-items",
        [
          _c("v-btn", { attrs: { text: "" }, on: { click: _vm.onClickDone } }, [
            _vm._v(" Done ")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }