var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "290" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-list-item",
                _vm._g(_vm._b({}, "v-list-item", attrs, false), on),
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            depressed: "",
                            block: "",
                            color: "error",
                            "max-width": "600px"
                          }
                        },
                        [_vm._v(" Logout ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "text-h5" }, [
            _vm._v(" Account Logout ")
          ]),
          _c("v-card-text", [_vm._v(" Are you sure you want to log out? ")]),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "darken-1",
                    text: "",
                    "data-testid": "logout-cancel"
                  },
                  on: {
                    click: function($event) {
                      _vm.dialog = false
                    }
                  }
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "red darken-1",
                    text: "",
                    "data-testid": "logout-confirm"
                  },
                  on: { click: _vm.onClickLogout }
                },
                [_vm._v(" Log Me Out ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }