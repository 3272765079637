<template>
  <v-dialog v-model="show" fullscreen hide-overlay transition="dialog-bottom-transition" class="dialog">
    <div id="settings" data-testid="settings">
      <TopBar @done="onClickDone" />
      <v-container class="inner-container">
        <v-list two-line subheader>
          <v-subheader>Scanning</v-subheader>
          <v-list-item>
            <v-list-item-content>
              <v-select
                data-testid="scan-type-sel"
                label="Verify"
                :items="scanTypes"
                v-model="selectedScanType"
                outlined
                hint="Set the type of verification you want to do"
                persistent-hint
              />
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-select
                data-testid="input-type-sel"
                label="Input From"
                :items="inputTypes"
                v-model="selectedInputType"
                outlined
                hint="Set the input type used for scanning"
                persistent-hint
              />
            </v-list-item-content>
          </v-list-item>
          <v-subheader v-if="showGeneralSection">General</v-subheader>
          <v-list-item v-if="showHidePersonalInfoSetting" :input-value="hidePassPersonalInfo" @click="onClickHidePassPersonalInfo" three-line>
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-checkbox :input-value="active" data-testid="hide-pass-info-cb"></v-checkbox>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>Hide pass personal info</v-list-item-title>
                <v-list-item-subtitle>
                  Hides all personal information of a scanned pass and only shows the state and title of the pass (red or green)
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-list-item>
          <v-subheader v-if="showTaggingSection">Tagging</v-subheader>
          <v-list-item v-if="showTagEventsSetting">
            <v-list-item-content>
              <v-select
                data-testid="event-tag-sel"
                label="Events"
                :items="tagEvents"
                v-model="selectedTagEvent"
                outlined
                hint="Tag all scans with the selected event"
                no-data-text="No upcomming events"
                persistent-hint
                clearable
              >
                <template v-slot:item="{ item }">
                  {{ item.name }}
                </template>
                <template v-slot:selection="{ item }">
                  {{ item.name }}
                </template>
              </v-select>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="showTagEventLocationsSetting">
            <v-list-item-content>
              <v-select
                data-testid="event-location-sel"
                label="Scan Location (optional)"
                :items="selectedTagEvent.scanLocations"
                v-model="selectedTagEventLocation"
                outlined
                hint="The location you'll be scanning from at the selected event."
                no-data-text="No locations for this event"
                persistent-hint
                clearable
              >
                <template v-slot:item="{ item }">
                  {{ item.name }}
                </template>
                <template v-slot:selection="{ item }">
                  {{ item.name }}
                </template>
              </v-select>
            </v-list-item-content>
          </v-list-item>

          <v-subheader>Account</v-subheader>
          <v-list-item>
            <v-list-item-avatar color="grey">
              <img v-if="userImage" :src="userImage" alt="User Image" />
              <v-icon v-else color="white">mdi-account-circle-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-if="userName">{{ userName }}</v-list-item-title>
              <v-list-item-title v-else>{{ email }}</v-list-item-title>
              <v-list-item-subtitle v-if="userName">{{ email }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <LogoutListItem @clickLogout="onClickLogout" />
        </v-list>
        <div class="version">Version: {{ version }}</div>
      </v-container>
    </div>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import TopBar from './components/top-bar.vue';
import LogoutListItem from './components/logout-list-item';
import { userIsAllowedToViewBasicDetails, userIsAllowedToTagEvents } from '@/utils/permissions';
import {
  SCAN_TYPE_LOCALS_VACCINATED,
  SCAN_TYPES_DESCRIPTIONS,
  INPUT_TYPES_DESCRIPTIONS,
  scanTypeFromDescription,
  inputTypeFromDescription,
  scanTypeToDescription,
  inputTypeToDescription
} from '@/utils/type-formats';

export default {
  name: 'Settings',
  components: { TopBar, LogoutListItem },
  data() {
    return {
      show: true,
      scanTypes: SCAN_TYPES_DESCRIPTIONS,
      selectedScanType: SCAN_TYPES_DESCRIPTIONS[0],
      inputTypes: INPUT_TYPES_DESCRIPTIONS,
      selectedInputType: INPUT_TYPES_DESCRIPTIONS[0]
    };
  },
  computed: {
    ...mapState({
      hidePassPersonalInfo: s => s.hidePassPersonalInfo,
      showHidePersonalInfoSetting: s => userIsAllowedToViewBasicDetails(s.user),
      showTagEventsSetting: s => {
        const type = s?.verifier?.scanType;
        return type === SCAN_TYPE_LOCALS_VACCINATED && userIsAllowedToTagEvents(s.user) && s.tagEvents?.length > 0;
      },
      showTagEventLocationsSetting: s => {
        const event = s?.selectedTagEvent;
        return event && event.scanLocations?.length > 0;
      },
      tagEvents: s => s.tagEvents,
      userImage: state => state.user?.image,
      userName: state => state.user?.name,
      email: state => state.user?.email,
      version: state => state.config.appVersion
    }),
    selectedTagEvent: {
      get() {
        return this.$store.state.selectedTagEvent;
      },
      set(val) {
        this.$store.dispatch('setTagEvent', val);
      }
    },
    selectedTagEventLocation: {
      get() {
        const event = this.selectedTagEvent;
        const locationID = this.$store.state.selectedTagEventLocationID;
        return event?.scanLocations.find(l => l._id === locationID);
      },
      set(val) {
        this.$store.dispatch('setTagEventLocation', val);
      }
    },
    showGeneralSection() {
      return this.showHidePersonalInfoSetting;
    },
    showTaggingSection() {
      return this.showTagEventsSetting;
    }
  },
  mounted() {
    this.setSelectScanType();
    this.setSelectInputType();
    this.loadTagEvents();
  },
  watch: {
    selectedScanType(newValue) {
      const scanType = scanTypeFromDescription(newValue);
      this.$store.commit('SET_VERIFIER_SCAN_TYPE', scanType);
    },
    selectedInputType(newValue) {
      const inputType = inputTypeFromDescription(newValue);
      this.$store.commit('SET_VERIFIER_INPUT_TYPE', inputType);
    }
  },
  methods: {
    setSelectScanType() {
      this.selectedScanType = scanTypeToDescription(this.$store?.state?.verifier?.scanType);
    },
    setSelectInputType() {
      this.selectedInputType = inputTypeToDescription(this.$store?.state?.verifier?.inputType);
    },
    async loadTagEvents() {
      await this.$store.dispatch('getUpcommingEvents');
    },
    onClickHidePassPersonalInfo() {
      this.$store.commit('SET_HIDE_PASS_PERSONAL_INFO', !this.hidePassPersonalInfo);
    },

    onClickDone() {
      this.show = false;
      setTimeout(() => {
        this.$router.push('/');
        this.$emit('dimissed');
      }, 300);
    },
    onClickLogout() {
      this.$store.dispatch('logout');
    }
  }
};
</script>

<style lang="scss" scoped>
#settings {
  background-color: white;
  width: 100%;
  height: 100%;
  z-index: 1001;
  box-sizing: border-box;

  .version {
    font-size: 10px;
    padding-top: 10px;
    padding-left: 16px;
    text-align: center;
    background: white;
  }
}
</style>
