<template>
  <v-toolbar dense>
    <v-toolbar-title>
      Settings
    </v-toolbar-title>
    <v-spacer />
    <v-toolbar-items>
      <v-btn text @click="onClickDone">
        Done
      </v-btn>
    </v-toolbar-items>
  </v-toolbar>
</template>

<script>
export default {
  name: 'TopBar',

  methods: {
    onClickDone() {
      this.$emit('done');
    }
  }
};
</script>

<style lang="scss" scoped>
.v-toolbar {
  padding-top: env(safe-area-inset-top) !important;
  height: calc(env(safe-area-inset-top) + 48px) !important;
}
</style>
